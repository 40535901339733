"use client";

import { Divider } from "@aidnas/design-system-bandaid";
import { usePathname } from "next/navigation";

import PortableText from "../PortableText";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { SiteQueryResult } from "@/types/sanity";

type Props = Pick<NonNullable<SiteQueryResult>, "footer" | "_id"> & { signedIn: boolean };

const bem = BEMHelper(styles);

export default function Footer(props: Props) {
  const pathname = usePathname();

  const { footer } = props || {};

  if (
    !footer ||
    !props.signedIn ||
    pathname.match(new RegExp(`^(?:/${props._id})?(?:/(?:login)?)?$`))
  ) {
    return null;
  }

  return (
    <footer {...bem("")}>
      <Divider />

      <PortableText value={footer} />
    </footer>
  );
}
